// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #349966;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #191A32;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2e875a;
  --ion-color-primary-tint: #48a375;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

// @media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body:not(.light).dark {
    --ion-color-primary: #349966;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #c0c0c0;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #2e875a;
    --ion-color-primary-tint: #48a375;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  // .ios body:not(.light) {
  //   --ion-background-color: #191A32;
  //   --ion-background-color-rgb: 0,0,0;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255,255,255;

  //   --ion-color-step-50: #0d0d0d;
  //   --ion-color-step-100: #1a1a1a;
  //   --ion-color-step-150: #262626;
  //   --ion-color-step-200: #333333;
  //   --ion-color-step-250: #404040;
  //   --ion-color-step-300: #4d4d4d;
  //   --ion-color-step-350: #595959;
  //   --ion-color-step-400: #666666;
  //   --ion-color-step-450: #737373;
  //   --ion-color-step-500: #808080;
  //   --ion-color-step-550: #8c8c8c;
  //   --ion-color-step-600: #999999;
  //   --ion-color-step-650: #a6a6a6;
  //   --ion-color-step-700: #b3b3b3;
  //   --ion-color-step-750: #bfbfbf;
  //   --ion-color-step-800: #cccccc;
  //   --ion-color-step-850: #d9d9d9;
  //   --ion-color-step-900: #e6e6e6;
  //   --ion-color-step-950: #f2f2f2;

  //   --ion-item-background: #191A32;

  //   --ion-card-background: #191A32;
  // }

  // .ios ion-modal {
  //   --ion-background-color: var(--ion-color-step-100);
  //   --ion-toolbar-background: var(--ion-color-step-150);
  //   --ion-toolbar-border-color: var(--ion-color-step-250);
  // }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
  
   .md body:not(.dark).light, body.light {
      // --ion-background-color: #c0c0c0;
      --ion-background-color: #fff;
      --ion-background-color-rgb: 25,26,50;

      --ion-text-color: #4f4f4f;
      --ion-text-color-rgb: 255,255,255;

      --ion-color-step-950: #25253c;
      --ion-color-step-900: #303147;
      --ion-color-step-850: #3c3c51;
      --ion-color-step-800: #47485b;
      --ion-color-step-750: #535365;
      --ion-color-step-700: #5e5f70;
      --ion-color-step-650: #6a6a7a;
      --ion-color-step-600: #757684;
      --ion-color-step-550: #81818e;
      --ion-color-step-500: #8c8d99;
      --ion-color-step-450: #9898a3;
      --ion-color-step-400: #a3a3ad;
      --ion-color-step-350: #afafb7;
      --ion-color-step-300: #babac2;
      --ion-color-step-250: #c6c6cc;
      --ion-color-step-200: #d1d1d6;
      --ion-color-step-150: #dddde0;
      --ion-color-step-100: #e8e8eb;
      --ion-color-step-50: #f4f4f5;

      --ion-border-color: #999CAD;
      --ion-item-background: #ffffff;
      --ion-toolbar-background: #ffffff;
      --ion-tab-bar-background: #ffffff;
      --ion-card-background: #ffffff;
      
      
      --ion-custom-icon: #4f4f4f;
   }

  .md body:not(.light).dark {
    --ion-background-color: #191A32;
    --ion-background-color-rgb: 25,26,50;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #25253c;
    --ion-color-step-100: #303147;
    --ion-color-step-150: #3c3c51;
    --ion-color-step-200: #47485b;
    --ion-color-step-250: #535365;
    --ion-color-step-300: #5e5f70;
    --ion-color-step-350: #6a6a7a;
    --ion-color-step-400: #757684;
    --ion-color-step-450: #81818e;
    --ion-color-step-500: #8c8d99;
    --ion-color-step-550: #9898a3;
    --ion-color-step-600: #a3a3ad;
    --ion-color-step-650: #afafb7;
    --ion-color-step-700: #babac2;
    --ion-color-step-750: #c6c6cc;
    --ion-color-step-800: #d1d1d6;
    --ion-color-step-850: #dddde0;
    --ion-color-step-900: #e8e8eb;
    --ion-color-step-950: #f4f4f5;

    --ion-border-color: #323755;
    --ion-item-background: #191A32;
    --ion-toolbar-background: #191A32;
    --ion-tab-bar-background: #191A32;
    --ion-card-background: #191A32;


    --ion-custom-icon: #ffffff;
  }
// }
