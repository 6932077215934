/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
    // font-family: 'Zilla Slab Highlight', cursive !important;
    font-family: "Montserrat", sans-serif !important;
}

input.searchbar-input {
    border: 1px solid #323755 !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}

// .ion-page {
//     overflow-y: auto;
// }

.stock-modal {
    max-height: 90%;
    // max-width: 900px;
    // width: 50%;
    // height: 50%;
    .modal-wrapper {
        width: 100%;
        height: 100%;
        @media screen and (min-width: 600px) {
            width: 1000px;
            max-height: 90%;
        }
        
    }
}

.searchbar-search-icon {
    background-image: url("https://unpkg.com/ionicons@5.5.1/dist/svg/search-outline.svg") !important;
}

.highcharts-container {
    width: calc(100% - 60px) !important;
    margin: 0 auto;
    // width: 100% !important;
    
    .highcharts-root {
        width: 100%;
    }
}

.full-width-highcharts .highcharts-container {
    width: calc(100%) !important;
}

.hundredpx-height-highcharts .highcharts-container {
    max-height: 60px;
}

.hundredpx-height-highcharts .highcharts-root {
    height: 100%;
    max-height: 60px;
    height: 60px;
    // border: 1px solid rgba(255,255,255,0.1);
  }


.highcharts-background {
    fill: transparent;
}

.highcharts-subtitle {
    text-transform: capitalize !important;
}








.toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #151529;
    border-radius: 17px;
    height: 34px;
    margin: 0 30px 15px 30px;
    color: #ADAFBB;
  
    &-option {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: calc((100% - 8px) / 3);
        height: calc(100% - 4px);
        font-size: 13px;
        border-radius: 15px;
        margin: 2px;
        padding: 0 20px;
        font-weight: 500;
  
        &--selected {
            background-color: #191a32;
            color: #48a375;
            font-weight: 550;
        }
    }
}

body.light {
    .toggle {
        background-color: #e3e3e3;  
        color: #191a32;

        .toggle-option.toggle-option--selected {
        background-color: #48A375;
        color: white;
        }
    }

    .stock-item {
        background-color: white;
        border: 1px solid #e3e3e3;

        .logo-container {
            background-color: #fff!important;
            // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
            // border: 1px solid #e3e3e3;
        }

        .logo-container--logo.logo-container--logo-placeholder {
            color: #191a32;
        }

        .stock-name, 
        .stock-ticker, 
        .stock-item-indicator__frequency {
            color: #191a32 !important;
        }
    }

    .news-item, 
    .header-button, 
    input.searchbar-input {
        border-color: #e3e3e3 !important;
    }

    .divider {
        background-color: #e3e3e3 !important;
    }

    app-dollar-cost-average-calculator {
        .form-field {
            border-color: #e3e3e3 !important;
            .increase-button, .decrease-button, input {
                border-color: #e3e3e3 !important;
            }
        }
    }

    app-account {
        .profile-icon, .form-field input {
            border-color: #e3e3e3 !important;
        }


        .form-field--toggle {
            ion-icon {
                color: #323755 !important;
                &:not(.active-icon) {
                    color: #48A375 !important;
                }
            }

            .theme-toggle {
                --background: #e3e3e3;
                --box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
                --border: 1px solid #c5c5c5;
                --handle-background: #48A375;
            }
        }
        .toggle-icon {
            
            .toggle-inner {
                background-color: #48A375 !important;
            }
        }
    
        .form-field--toggle.active-icon {
            background-color: #323755 !important;
        }
    }
}